import React, { useContext, useState } from 'react'

import moment from 'moment'
import Moment from 'react-moment'

import { gql, useMutation } from '@apollo/client'

import { Check } from '@mui/icons-material'
import IconAdd from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import IconDelete from '@mui/icons-material/Delete'
import { default as IconEdit } from '@mui/icons-material/Edit'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import IconSync from '@mui/icons-material/Sync'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import 'moment/locale/en-in'
import 'moment/locale/mk'

import WebPosSettings from './WebPosSettings'
import { RestaurantContext, UserContext } from '../../App'
import LoadingBox from '../../signin/common/LoadingBox'
import Add from '../common/Add'
import MobileDialog from '../common/MobileDialog'
import { useEntityRelationQuery } from '../common/useEntityQuery'

const useStyles = makeStyles((theme) => ({
  itemCard: {
    display: 'block',
    overflow: 'unset',
    textAlign: 'left',
  },
  itemCardDisabled: {
    display: 'block',
    overflow: 'unset',
    textAlign: 'left',
    backgroundColor: theme.palette.primary.light, // 'rgba(0, 0, 0, 0.05)'
  },
  cardHeader: {
    width: `inherit`,
    marginTop: '-30px',
    borderRadius: `10px`,
    boxShadow: `0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)`,
    backgroundColor: theme.palette.primary.main, // `rgb(0, 51, 102, 0.8)`,
    color: `#fff`,
  },
  cardHeaderRow: {
    padding: `30px 10px`,
  },
  cardHeaderTitle: {
    width: `inherit`,
    padding: `30px`,
    margin: '-30px',
  },
  cardHeaderIcons: {
    float: `right`,
    padding: '7px',
    marginTop: '-7px',
    width: '35px',
  },
  cardMenuIcons1: {
    right: `40px`,
    top: `-5px`,
    position: `absolute`,
    zIndex: '99',
    padding: '7px',
    width: '35px',
  },
  cardMenuIcons2: {
    right: `0`,
    top: `-5px`,
    position: `absolute`,
    zIndex: '99',
    padding: '7px',
    width: '35px',
  },
  posName: {
    position: 'relative',
    flexGrow: 1,
  },
  menuTitle: {
    padding: theme.spacing(1),
    display: 'block',
    fontSize: 12,
    width: `75%`,
  },
  itemName: {
    margin: `${theme.spacing(2)} 0 0 0`,
    padding: theme.spacing(1),
    flexGrow: 1,
  },
}))

const tryJSONParse = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    console.log(str, e)
    return {}
  }
}

export default function PosItem(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)
  const pos = props.pos

  const [fieldType, setFieldType] = useState(false)
  const [showLastClosure, setShowLastClosure] = useState(false)
  const [showPosEvents, setShowPosEvents] = useState(false)
  const [now] = useState(Date.now())

  const locale = moment().locale(user.lang)

  // Get PoS name localized
  const posName = pos.name[user.lang]

  const fieldList = []

  const EDIT_ITEM = `
    mutation ($id: String!, $restaurantId: String!, $defOrderType: Long!, $langId: String!, ${
      user.me.profile && user.me.profile.isAdmin ? '$nameMk: String!, $nameEn: String!' : '$name: String!'
    }) {
		createPos(input: {
			id: $id,
			${user.me.profile && user.me.profile.isAdmin ? user.gqlEditNameTr('$nameMk', '$nameEn', '$langId') : user.gqlEditName('$name', '$langId')}
			defOrderType: $defOrderType
			restaurantId: $restaurantId
		}) {
			id
		}
    }`

  const ADD_MENU_ITEM = `
    mutation ($posId: String!, $menuId: String!, $displayIndex: Long!) {
        hardDeletePosMenu(posId: $posId, menuId: $menuId)
		createPosMenu(input: {
			posId: $posId,
			menuId: $menuId
			displayIndex: $displayIndex
		}) {
			posId
		}
    }`

  const EDIT_MENU_ITEM = `
    mutation ($restaurantId: String!, $menuId: String!, $langId: String!, $name: String!) {
		createRestaurantMenu(input: {
			id: $menuId
			${user.gqlEditName('$name', '$langId')}
			restaurantId: $restaurantId
		}) {
			id
		}
    }`

  const EDIT_DEPARTMENTS_ITEM = `
    mutation ($posId: String!, $productionDepartmentId: String!) {
      	extras_assignProductionDepartmentToPos(posId: $posId, productionDepartmentId: $productionDepartmentId)
    }`

  const DEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editPos(input: {
                id: $id,
                active: 0
            }) {
                id
            }
        }`
  const UNDEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editPos(input: {
                id: $id,
                active: 1
            }) {
                id
            }
        }`

  const [editState, setEditState] = useState({
    action: '',
    posMenu: {},
    cat: {},
    department: {},
  })

  const [addState, setAddState] = useState({
    posId: '',
  })

  const [openManipulateBox, setOpenManipulateBox] = useState(false)
  const [openAddMenuBox, setAddMenuBox] = useState(false)

  const EDIT_ORDER_TYPE = gql`
    mutation ($id: String!, $${fieldType}: Long!) {
		editPos(input: {
			id: $id,
			${fieldType}: $${fieldType}
		}) {
			id
            ${fieldType}
		}
    }`
  const [editOrderType, { data: data_order_type, loading: loading_order_type }] = useMutation(EDIT_ORDER_TYPE, {
    skip: !fieldType,
  })

  const REQUEST_RESYNC = gql`
    mutation {
      createDeviceRequest(input: { deviceUID: "${pos.deviceUUID}", restaurantId: "${restaurant.id}", type: "RE_SYNC", active: 1 }) {
        id
        deviceUID
        handled
        created
        validUntil
        type
      }
    }
  `

  const [requestResync, { data: dataResyncRequest, loading: loadingResyncRequest }] = useMutation(REQUEST_RESYNC)

  const deviceRequests =
    dataResyncRequest?.createDeviceRequest && !props.deviceRequests.find((it) => it.id === dataResyncRequest.createDeviceRequest.id)
      ? [dataResyncRequest.createDeviceRequest].concat(props.deviceRequests)
      : props.deviceRequests
  const resyncRequests = deviceRequests
    .filter((it) => it.type === 'RE_SYNC')
    .distinctBy((it) => it.id)
    .sort((a, b) => b.created - a.created)

  if (fieldType) {
    const items = {
      id: pos.id,
      [fieldType]: pos[fieldType] ? 0 : 1,
    }
    user.consoleLog(items)
    user.consoleLog(pos[fieldType])
    editOrderType({ variables: items })
  }

  if (fieldType && !loading_order_type && data_order_type && data_order_type[fieldType] !== pos[fieldType]) {
    user.consoleLog('Refetch Done!')
    // props.refetch();
    setFieldType(false)
  }

  const editPosOrderType = (type) => {
    setFieldType(type)
  }

  const onPosEdit = () => {
    setEditState({
      action: 'pos',
      posMenu: {},
      cat: {},
      department: {},
    })
    setOpenManipulateBox(true)
  }

  const onPosMenuEdit = (event, posMenu) => {
    setEditState({
      action: 'posMenu',
      posMenu,
      cat: {},
      department: {},
    })
    setOpenManipulateBox(true)
  }

  const onCategoryEdit = (event, cat, department) => {
    setEditState({
      action: 'category',
      posMenu: {},
      cat,
      department,
    })
    setOpenManipulateBox(true)
  }

  const onAddMenuClick = (event) => {
    setAddState({
      posId: pos.id,
    })
    setAddMenuBox(true)
  }

  const onResyncMenuClick = (event) => {
    if (confirm(user.translate('confirm_resync'))) {
      requestResync()
    }
  }

  const onDeleteClickWarning = () => {
    props.setOpenSnackBarWarning(true)
  }

  const onDeleteClick = (event, action, posId, name, itemId) => {
    let $deleteItem = ``
    let $undeleteItem = ``
    let variables = {}

    if (action === 'pos') {
      $deleteItem = `
			mutation ($id: String!) {
				deletePos(id: $id)
			}`
      $undeleteItem = `
			mutation ($id: String!) {
				undeletePos(id: $id)
			}`
      variables = { id: posId }
      props.setDeleteState({
        name,
        deleteItem: $deleteItem,
        undeleteItem: $undeleteItem,
        variables,
        hideUndo: false,
      })
    } else if (action === 'posMenu') {
      $deleteItem = `
			mutation ($posId: String!, $menuId: String!) {
				hardDeletePosMenu(posId: $posId, menuId: $menuId)
			}`
      $undeleteItem = `
			mutation ($posId: String!, $menuId: String!) {
				undeletePosMenu(posId: $posId, menuId: $menuId)
			}`
      variables = { posId, menuId: itemId }
      props.setDeleteState({
        name,
        deleteItem: $deleteItem,
        undeleteItem: $undeleteItem,
        variables,
        hideUndo: false,
      })
    } else if (action === 'category') {
      $deleteItem = `
			mutation ($posId: String!, $productionDepartmentId: String!) {
				deletePosProduction(posId: $posId, productionDepartmentId: $productionDepartmentId)
			}`
      $undeleteItem = `
			mutation ($posId: String!, $productionDepartmentId: String!) {
				undeletePosroduction(posId: $posId, productionDepartmentId: $productionDepartmentId)
			}`
      variables = { posId, productionDepartmentId: itemId }
      props.setDeleteState({
        name,
        deleteItem: $deleteItem,
        undeleteItem: $undeleteItem,
        variables,
        hideUndo: true,
      })
    }

    props.setOpenDeleteBox(true)
  }

  const renderAddMenuItem = (fieldList) => {
    fieldList = [
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'posId',
        fieldLabel: 'posId',
        fieldValue: addState.posId,
      },
      {
        required: true,
        type: 'autocomplete',
        fieldType: 'autocomplete',
        fieldName: 'menuId',
        fieldLabel: user.translate('menu'),
        fieldValue: props.firstPosMenusId,
        fieldOptions: props.posMenusFieldOptions,
      },
      {
        required: true,
        type: 'hidden',
        fieldType: 'text',
        fieldName: 'displayIndex',
        fieldLabel: 'displayIndex',
        fieldValue: pos.posMenus.length + 1,
      },
    ]

    return (
      <Add
        fieldList={fieldList}
        openManipulateBox={setAddMenuBox}
        actionType={user.translate('add')}
        name={user.translate('pos_menu_item')}
        restaurant={restaurant}
        manipulateItem={ADD_MENU_ITEM}
        onSuccess={props.refetch}
      />
    )
  }

  const renderEdit = (fieldList) => {
    if (editState.action === 'pos') {
      fieldList = [
        {
          required: true,
          type: 'text',
          fieldType: 'text',
          fieldName: 'name',
          fieldLabel: user.translate('title'),
          fieldValue: pos.name[user.lang],
        },
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'id',
          fieldLabel: 'id',
          fieldValue: pos.id,
        },
        {
          required: true,
          type: 'select',
          fieldType: 'select',
          fieldName: 'defOrderType',
          fieldLabel: user.translate('order_type'),
          fieldValue: '' + pos.defOrderType,
          fieldOptions: props.orderTypes,
          noNone: true,
        },
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'langId',
          fieldLabel: 'langId',
          fieldValue: pos.name.id,
        },
      ]

      if (user.me.profile && user.me.profile.isAdmin) {
        fieldList = [
          {
            required: true,
            type: 'text',
            fieldType: 'text',
            fieldName: 'nameMk',
            fieldLabel: user.translate('titleMk'),
            fieldValue: pos.name.mk,
          },
          {
            required: true,
            type: 'text',
            fieldType: 'text',
            fieldName: 'nameEn',
            fieldLabel: user.translate('titleEn'),
            fieldValue: pos.name.en,
          },
          {
            required: true,
            type: 'hidden',
            fieldType: 'text',
            fieldName: 'id',
            fieldLabel: 'id',
            fieldValue: pos.id,
          },
          {
            required: true,
            type: 'select',
            fieldType: 'select',
            fieldName: 'defOrderType',
            fieldLabel: user.translate('order_type'),
            fieldValue: '' + pos.defOrderType,
            fieldOptions: props.orderTypes,
            noNone: true,
          },
          {
            required: true,
            type: 'hidden',
            fieldType: 'text',
            fieldName: 'langId',
            fieldLabel: 'langId',
            fieldValue: pos.name.id,
          },
        ]
      }

      // user.consoleLog(pos.active)

      return (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={pos.name[user.lang] || ''}
          manipulateItem={EDIT_ITEM}
          deactivate={!!pos.active}
          deactivateItem={DEACTIVATE_ITEM}
          unDeactivateItem={UNDEACTIVATE_ITEM}
          onSuccess={props.refetch}
        />
      )
    } else if (editState.action === 'posMenu') {
      fieldList = [
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'menuId',
          fieldLabel: 'menuId',
          fieldValue: '' + editState.posMenu.id,
          fieldOptions: props.posMenusFieldOptions,
        },
        {
          required: true,
          type: 'text',
          fieldType: 'text',
          fieldName: 'name',
          fieldLabel: user.translate('title'),
          fieldValue: editState.posMenu.name[user.lang],
        },
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'langId',
          fieldLabel: 'langId',
          fieldValue: editState.posMenu.name.id,
        },
      ]

      return (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={editState.posMenu.name[user.lang] || ''}
          manipulateItem={EDIT_MENU_ITEM}
          onSuccess={props.refetch}
        />
      )
    } else if (editState.action === 'category') {
      fieldList = [
        {
          required: true,
          type: 'hidden',
          fieldType: 'text',
          fieldName: 'posId',
          fieldLabel: 'posId',
          fieldValue: '' + pos.id,
          fieldOptions: props.posMenusFieldOptions,
        },
        {
          required: true,
          type: 'select',
          fieldType: 'select',
          fieldName: 'productionDepartmentId',
          fieldLabel: user.translate('production_department'),
          fieldValue: '' + (editState.department !== '' ? editState.department.id : props.firstProductionDepartmentId),
          fieldOptions: props.productionDepartmentsFieldOptions[editState.cat.id],
          noNone: true,
        },
      ]

      return (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate('edit')}
          restaurant={restaurant}
          name={editState.cat.name[user.lang] || ''}
          manipulateItem={EDIT_DEPARTMENTS_ITEM}
          onSuccess={props.refetch}
        />
      )
    }
  }

  let foundPosMenu = false

  props.pos.posMenus.forEach((posMenu) => {
    posMenu = props.posMenus.find((c) => c.id === posMenu.menuId)
    if (typeof posMenu !== 'undefined') {
      foundPosMenu = true
    }
  })

  let terminalIP = '-'
  let terminalVersion = '0'
  try {
    const syncUrl = pos.syncUrl ? new URL((pos.syncUrl ?? '').split('|')[0]) : undefined
    if (syncUrl) {
      terminalIP = syncUrl.hostname
      terminalVersion = syncUrl.searchParams.get('v')
    }
  } catch (e) {}

  const posEventsQuery = useEntityRelationQuery(
    'PosEvent',
    [
      { name: 'id', gql: 'String!' },
      { name: 'name', gql: 'String!' },
      {
        name: 'device',
        gql: 'String!',
      },
      { name: 'content', gql: 'String!' },
      { name: 'time', gql: 'Long!' },
    ],
    'posId',
    props.pos.id,
    {
      filter: {
        by: 'time',
        gt: now - 14 * 24 * 3600 * 1000,
      },
    },
  )

  const posEvents = posEventsQuery.items?.orderByDesc((it) => it.time) ?? []
  const posEventClosures = posEvents.filter((it) => it.name === 'daily_closure')
  const lastClosure = posEventClosures.maxBy((it) => it.time)
  const lastClosureTime = lastClosure && moment(parseInt(lastClosure.time)).locale(locale).calendar()

  const taxes = ['18%', '10%', '5%', '0%']

  return (
    <React.Fragment>
      <Card className={pos.active ? classes.itemCard : classes.itemCardDisabled}>
        <CardContent>
          <Stack className={classes.cardHeader} sx={{ flexGrow: 1, p: 1, pt: 2 }} spacing={0}>
            <Box sx={{ flexGrow: 1 }}>
              <Tooltip title={`${user.translate('edit')} ` + posName} placement="bottom-start" className={classes.cardHeaderTitle}>
                <Button color="inherit" component="h6" onClick={onPosEdit}>
                  {posName}
                </Button>
              </Tooltip>

              <Tooltip title={`${user.translate('delete')} ` + posName} placement="bottom-start" className={classes.cardHeaderIcons}>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={(e) => (pos.restaurantTablesCount === 0 ? onDeleteClick(e, 'pos', pos.id, posName) : onDeleteClickWarning())}
                >
                  <IconDelete fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title={`${user.translate('edit')} ` + posName} placement="bottom-start" className={classes.cardHeaderIcons}>
                <IconButton size="small" color="inherit" onClick={onPosEdit}>
                  <IconEdit fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title={user.translate('add_menu')} placement="bottom-start" className={classes.cardHeaderIcons}>
                <IconButton size="small" color="inherit" onClick={onAddMenuClick}>
                  <IconAdd fontSize="small" />
                </IconButton>
              </Tooltip>

              {user.me.profile.isAdmin && (
                <Tooltip title={user.translate('resync')} placement="bottom-start" className={classes.cardHeaderIcons}>
                  {loadingResyncRequest ? (
                    <LoadingBox />
                  ) : (
                    <IconButton size="small" color="inherit" onClick={onResyncMenuClick}>
                      <IconSync fontSize="small" />
                    </IconButton>
                  )}
                </Tooltip>
              )}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="h6"
                style={{
                  color: '#FFF',
                  marginTop: 4,
                  marginBottom: 4,
                  padding: 0,
                  fontWeight: 400,
                }}
              >
                {terminalIP}
                <span style={{ float: 'right' }}>V.{terminalVersion}</span>
              </Box>
              <Box
                component="h6"
                style={{
                  marginTop: 4,
                  marginBottom: 8,
                  paddingBottom: 0,
                  marginLeft: -6,
                  fontWeight: 400,
                }}
              >
                <Button size="small" style={{ fontSize: 10, paddingTop: 6, color: '#FFF' }} onClick={() => setShowLastClosure(true)}>
                  {lastClosure ? (
                    <>
                      {user.translate('last_daily_closure')}:&nbsp;
                      <b style={{ color: '#0EE' }}>{lastClosureTime}</b>
                    </>
                  ) : (
                    <>{user.translate('last_daily_closure')}:&nbsp;/</>
                  )}
                </Button>
                <Tooltip title={user.translate('pos_events_last_14_days')}>
                  <IconButton
                    size="small"
                    style={{
                      float: 'right',
                      color: '#AFE',
                      fontSize: 12,
                      minWidth: 32,
                    }}
                    onClick={() => setShowPosEvents(true)}
                  >
                    <span
                      style={{
                        backgroundColor: '#888',
                        borderRadius: '50%',
                        width: 22,
                        height: 22,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#AFA',
                        fontWeight: 600,
                      }}
                    >
                      {posEvents.length}
                    </span>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, display: resyncRequests.length ? '' : 'none' }}>
              <Stack direction="column">
                <Typography variant="caption" style={{ color: '#FFF' }}>
                  {user.translate('resync')}
                </Typography>
                {resyncRequests.map((it) => (
                  <Stack direction="row" key={it.id} justifyContent="space-between" sx={{ pt: 0.5, pb: 0.5 }}>
                    <Typography key={it.id} variant="caption" style={{ fontSize: 10, color: '#FFF', textTransform: 'uppercase' }}>
                      {moment(it.created).fromNow()}
                    </Typography>
                    <Tooltip
                      title={
                        it.handled
                          ? user.translate('resync_handled') + moment(parseInt(it.handled)).fromNow()
                          : user.translate('resync_not_handled') + moment(parseInt(it.validUntil)).fromNow()
                      }
                    >
                      {it.handled ? <Check fontSize="small" color="success" /> : <CircularProgress size={16} color="warning" />}
                    </Tooltip>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Stack>

          <Box component="h6" className={classes.itemName}>
            {user.translate('production_departments')}
          </Box>

          {props.productionCategorys.map((cat) => {
            let department = ''
            if (typeof props.pos.posProductionDepartments !== 'undefined') {
              department = props.pos.posProductionDepartments.find((c) => c.productionDepartment.productionCategoryId === cat.id)
              if (typeof department === 'undefined') {
                department = ''
              } else {
                department = department.productionDepartment
              }
            }
            const disabled = Object.keys(props.productionDepartmentsFieldOptions[cat.id]).length <= 0

            return (
              <div className={classes.posName} key={'pos_menu_' + cat.id}>
                <Tooltip title={`${user.translate('edit')} ` + cat.name[user.lang]} placement="bottom-start" className={classes.menuTitle}>
                  <Button disabled={disabled} color="inherit" component="p" onClick={(e) => onCategoryEdit(e, cat, department)}>
                    {cat.name[user.lang]}: {department !== '' ? department.name[user.lang] : ''}
                  </Button>
                </Tooltip>

                <Tooltip
                  title={`${user.translate('edit')} ${cat.name[user.lang]}${department !== '' ? ': ' + department.name[user.lang] : ''}`}
                  placement="bottom-start"
                  className={classes.cardMenuIcons1}
                >
                  <IconButton disabled={disabled} size="small" color="primary" onClick={(e) => onCategoryEdit(e, cat, department)}>
                    <IconEdit />
                  </IconButton>
                </Tooltip>

                {department !== '' && (
                  <Tooltip
                    title={`${user.translate('delete')} ${cat.name[user.lang]}: ${department.name[user.lang]}`}
                    placement="bottom-start"
                    className={classes.cardMenuIcons2}
                  >
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={(e) => onDeleteClick(e, 'category', pos.id, department.name[user.lang], department.id)}
                    >
                      <IconDelete />
                    </IconButton>
                  </Tooltip>
                )}
                {department === '' && (
                  <IconButton className={classes.cardMenuIcons2} size="small" color="primary" disabled>
                    <IconDelete />
                  </IconButton>
                )}
              </div>
            )
          })}

          {foundPosMenu && (
            <Box component="h6" className={classes.itemName}>
              {user.translate('menu')}
            </Box>
          )}

          {props.pos.posMenus.map((_posMenu) => {
            const posMenu = props.posMenus.find((c) => c.id === _posMenu.menuId)
            if (typeof posMenu !== 'undefined') {
              return (
                <div className={classes.posName} key={'pos_menu_' + posMenu.id}>
                  <Tooltip
                    title={`${user.translate('edit')} ` + posMenu.name[user.lang]}
                    placement="bottom-start"
                    className={classes.menuTitle}
                  >
                    <Button color="inherit" component="p" onClick={(e) => onPosMenuEdit(e, posMenu)}>
                      {posMenu.name[user.lang]}
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={`${user.translate('edit')} ` + posMenu.name[user.lang]}
                    placement="bottom-start"
                    className={classes.cardMenuIcons1}
                  >
                    <IconButton size="small" color="primary" onClick={(e) => onPosMenuEdit(e, posMenu)}>
                      <IconEdit />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title={`${user.translate('delete')} ` + posMenu.name[user.lang]}
                    placement="bottom-start"
                    className={classes.cardMenuIcons2}
                  >
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={(e) => onDeleteClick(e, 'posMenu', pos.id, posMenu.name[user.lang], posMenu.id)}
                    >
                      <IconDelete />
                    </IconButton>
                  </Tooltip>
                </div>
              )
            }
            return null
          })}

          <Box component="h6" className={classes.itemName}>
            {user.translate('order_type')}
          </Box>

          <div className={classes.posName} key="pos_order_0">
            <Tooltip
              title={`${user.translate('edit')} ${user.translate('in_restaurant')}`}
              placement="bottom-start"
              className={classes.menuTitle}
              onClick={(e) => editPosOrderType('irOrderType')}
            >
              <Button color="inherit" component="p">
                {user.translate('in_restaurant')}
              </Button>
            </Tooltip>

            <Tooltip
              title={`${user.translate('edit')}  ${user.translate('in_restaurant')}`}
              placement="bottom-start"
              className={classes.cardMenuIcons2}
              onClick={(e) => editPosOrderType('irOrderType')}
            >
              <IconButton size="small" color="primary">
                {pos.irOrderType ? <CheckIcon /> : <ClearIcon />}
              </IconButton>
            </Tooltip>
          </div>

          <div className={classes.posName} key="pos_order_1">
            <Tooltip
              title={`${user.translate('edit')} ${user.translate('take_away')}`}
              placement="bottom-start"
              className={classes.menuTitle}
              onClick={(e) => editPosOrderType('taOrderType')}
            >
              <Button color="inherit" component="p">
                {user.translate('take_away')}
              </Button>
            </Tooltip>

            <Tooltip
              title={`${user.translate('edit')}  ${user.translate('take_away')}`}
              placement="bottom-start"
              className={classes.cardMenuIcons2}
              onClick={(e) => editPosOrderType('taOrderType')}
            >
              <IconButton size="small" color="primary">
                {pos.taOrderType ? <CheckIcon /> : <ClearIcon />}
              </IconButton>
            </Tooltip>
          </div>

          <div className={classes.posName} key="pos_order_2">
            <Tooltip
              title={`${user.translate('edit')} ${user.translate('catering')}`}
              placement="bottom-start"
              className={classes.menuTitle}
              onClick={(e) => editPosOrderType('ctOrderType')}
            >
              <Button color="inherit" component="p">
                {user.translate('catering')}
              </Button>
            </Tooltip>

            <Tooltip
              title={`${user.translate('edit')}  ${user.translate('catering')}`}
              placement="bottom-start"
              className={classes.cardMenuIcons2}
              onClick={(e) => editPosOrderType('ctOrderType')}
            >
              <IconButton size="small" color="primary">
                {pos.ctOrderType ? <CheckIcon /> : <ClearIcon />}
              </IconButton>
            </Tooltip>
          </div>

          <WebPosSettings posId={props.pos.id} posName={props.pos.name} />
        </CardContent>
      </Card>

      {openAddMenuBox && renderAddMenuItem(fieldList)}

      {openManipulateBox && renderEdit(fieldList)}

      {showLastClosure && (
        <MobileDialog
          open={showLastClosure}
          onClose={setShowLastClosure}
          title={user.translate('last_closure') + '  - ' + props.pos.name[user.lang]}
        >
          {posEventClosures.map((item) => {
            let content = tryJSONParse(item.content)

            if (!content || content === 'null') {
              content = {
                result: {
                  errors: [user.translate('error')],
                },
              }
            } else if (typeof content === 'string') {
              content = tryJSONParse(content)
              if (typeof content === 'string') {
                content = {
                  result: {
                    errors: content
                      .replace(/^\[/, '')
                      .replace(/\]$/, '')
                      .split(',')
                      .map((it) => it.trim()),
                  },
                }
              }
            }
            const result = content.result ?? content.d
            const avatar = result?.Closure ? '#' + result.Closure : '-'

            const description = result.errors
              ? result.errors.map((it) => (
                  <React.Fragment key={it}>
                    {it}
                    <br />
                  </React.Fragment>
                ))
              : [
                  [user.translate('total') + ': ' + user.formatNumber(parseFloat(result.Total))],
                  [<br key="new_line" />],
                  [result.TaxA, result.TaxC, result.TaxB, result.TaxD]
                    .flatMap((it, index) => [
                      <b key={it} style={{ color: '#444' }}>
                        {taxes[index]}
                      </b>,
                      ': ',
                      user.formatNumber(parseFloat(it)),
                      ' - ',
                    ])
                    .slice(0, -1),
                ].flatMap((it) => it)

            const time = new Date(parseInt(item.time))
            const today = new Date()
            const format = time.getFullYear() === today.getFullYear() ? 'D MMM HH:mm' : 'D MMM YYYY HH:mm'

            return (
              <List key={item}>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar>
                      <Typography variant="caption" style={{ fontSize: 9 }}>
                        {avatar}
                      </Typography>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Moment format={format}>{parseInt(item.time)}</Moment>
                        <span style={{ float: 'right' }}>{content?.model}</span>
                      </>
                    }
                    secondary={description}
                  />
                </ListItem>
              </List>
            )
          })}
        </MobileDialog>
      )}
      {showPosEvents && (
        <MobileDialog
          open={showPosEvents}
          onClose={setShowPosEvents}
          title={user.translate('pos_events') + ' -  ' + props.pos.name[user.lang]}
        >
          {posEvents.map((item) => {
            const event = item.name.toLowerCase()
            const eventName = user.translate(event)
            const avatar = eventName
              .split(/[_\s]/)
              .map((it) => it.charAt(0).toUpperCase())
              .join('')

            let content = tryJSONParse(item.content)
            if (!content || content === 'null') {
              content = {
                result: {
                  errors: [user.translate('error')],
                },
              }
            } else if (typeof content === 'string') {
              content = tryJSONParse(content)
            } else if (content.error) {
              content = {
                ...content,
                result: {
                  errors: [content.error],
                },
              }
            }
            const result = content.result ?? content.d ?? {}
            let description = ''
            if (!content || content === 'null') {
              description = user.translate('error')
            } else if (result.errors) {
              description = result.errors.map((it) => (
                <React.Fragment key={it}>
                  {it}
                  <br />
                </React.Fragment>
              ))
            } else if (event === 'setup') {
              description = [
                <i key="desc">
                  <small>{decodeURIComponent(content.fiscal?.replaceAll('u00', '%') ?? '')}</small>
                </i>,
                <br key="new_line" />,
                <b key="info" style={{ color: '#444' }}>
                  WiFi
                </b>,
                `: ${content.wifiSsid} / ${content.wifiPass}`,
                <br key="new_line_2" />,
                <b key="new_info_2" style={{ color: '#444' }}>
                  ID
                </b>,
                `: ${item.device}`,
              ]
            } else if (event === 'cash_in' || event === 'cash_out') {
              const items = ['cash_in', 'cash_out']
              description = [
                [user.translate('total_cash') + ': ' + user.formatNumber(parseFloat(result.c ?? result.cashSum))],
                [<br key="new_line_2" />],
                [result.a ?? result.cashIn, result.b ?? result.cashOut]
                  .flatMap((it, index) => [
                    <b key={`item_${index}`} style={{ color: '#444' }}>
                      {user.translate(items[index])}
                    </b>,
                    ': ',
                    user.formatNumber(parseFloat(it)),
                    ' - ',
                  ])
                  .slice(0, -1),
              ].flatMap((it) => it)
            } else if (event === 'fiscal_unblock') {
              description = [user.translate(result ? 'unblocked' : 'not_blocked')]
            } else if (result?.Total) {
              description = [
                [user.translate('total') + ': ' + user.formatNumber(parseFloat(result.Total))],
                [<br key="new_line_total" />],
                [result.TaxA, result.TaxC, result.TaxB, result.TaxD]
                  .flatMap((it, index) => [
                    <b key={`tax_${index}`} style={{ color: '#444' }}>
                      {taxes[index]}
                    </b>,
                    ': ',
                    user.formatNumber(parseFloat(it)),
                    ' - ',
                  ])
                  .slice(0, -1),
              ].flatMap((it) => it)
            }

            const time = new Date(parseInt(item.time))
            const today = new Date()
            const format = time.getFullYear() === today.getFullYear() ? 'D MMM HH:mm' : 'D MMM YYYY HH:mm'

            return (
              <List key={item.id}>
                <ListItem dense>
                  <Tooltip title={eventName}>
                    <ListItemAvatar>
                      <Avatar>
                        <Typography>{avatar}</Typography>
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <>
                        <Moment format={format}>{parseInt(item.time)}</Moment>
                        <span style={{ float: 'right' }}>{content?.model}</span>
                      </>
                    }
                    secondary={description}
                  />
                </ListItem>
              </List>
            )
          })}
        </MobileDialog>
      )}
    </React.Fragment>
  )
}
